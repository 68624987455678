import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Accordion from 'react-bootstrap/Accordion';
import ProcessCarousel from './components/ProcessCarousel';
import TestimonialCarousel from './components/TestimonialCarousel';
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import './App.css';

import {
  ADDRESS,
  EMAIL,
  EMAIL_HREF,
  PHONE,
  PHONE_HREF,
  WEBSITE_LOGO,
  WEBSITE_NAME,
} from './config';
import {
  logo,
  amazonLogo,
  loader,
  images_amazon_bg,
  images_amazon_tel,
  images_banner,
  images_banner_form_bg,
  images_bn_logs,
  images_counter_before,
  images_counter_bg,
  images_footer,
  images_form_seal,
  images_our_allow,
  images_our_allow_after,
  images_our_allow_before,
  images_our_author,
  images_our_pub_before,
  images_our_pub_img,
  images_our_pub_txt_after,
  images_our_pub_ul,
  images_owl_arrow_next,
  images_owl_arrow_prev,
  images_process_before,
  images_tes_before,
} from './assets/images';
import { ModalSection } from './layout/modalSection';

function App() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "amazonkdppublishers.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=237046';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonkdppublishers.org/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  const year = new Date().getFullYear();

  useEffect(() => {
    // Define the function to toggle the chat
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.show();
      }
    };

    // Attach event listeners when the component mounts
    const chatButton = document.querySelectorAll('.chat'); // Replace '.chat' with the appropriate button selector

    chatButton.forEach(function (clickButton) {
      // clickButton.addEventListener('click', clickButton)
      if (clickButton) {
        console.log(clickButton);
        clickButton.addEventListener('click', toggleChat);
      }

      // Cleanup: Remove event listeners when the component unmounts
      return () => {
        if (clickButton) {
          console.log(clickButton);
          clickButton.removeEventListener('click', toggleChat);
        }
      };
    });
  }, []);

  return (
    <div className="App">
      <header>
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <a href="index" class="logo">
                <img
                  height="auto"
                  width="auto"
                  src={amazonLogo}
                  title="img"
                  alt="img"
                />
              </a>
            </div>
            <div class="col-md-9">
              <nav class="navbar-expand-md main-menu">
                <ul class="menu">
                  <li class="phone">
                    <i class="fas fa-phone"></i>
                    <a href={PHONE_HREF}>{PHONE}</a>
                  </li>
                  <li class="msg">
                    <a
                      href="javascript:;"
                      class="btn btn-dark various"
                      onClick={onOpenModal}
                    >
                      Get Started
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <section class="our-banner">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div class="row">
            <div class="col-md-7">
              <div class="info-banner">
                <h1>
                  <span>BOOK WRITING & PUBLISHING</span>
                  SERVICES OFFERED AT
                  <strong>AFFORDABLE PRICES!</strong>
                </h1>
                <ul>
                  <li>
                    Professional Writing, Editing, Proofreading, and Formatting
                  </li>
                  <li>
                    Publishing on Amazon, Kindle, Barnes & Noble, Google Books,
                    and More
                  </li>
                  <li>Premium Book Cover Design</li>
                  <li>Effective Book Marketing Strategies</li>
                </ul>

                <div class="btn-block">
                  <a href="javascript:;" class="btn btn-light chat">
                    Live Chat
                  </a>
                  <a
                    href="javascript:;"
                    data-fancybox=""
                    class="btn btn-dark various"
                    onClick={onOpenModal}
                  >
                    Get Started
                  </a>
                  <img
                    height="auto"
                    width="auto"
                    src={images_bn_logs}
                    // style="background: #fff;padding: 0 17px;margin-top: 33px;"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="info-contact-banner">
                <div class="head-cb">
                  <h6>Do You Have a Manuscript Ready?</h6>
                  <h4>PUBLISH YOUR BOOK</h4>
                  <div class="seal">
                    <img
                      height="auto"
                      width="auto"
                      src={images_form_seal}
                      title="img"
                      alt="img"
                    />
                  </div>
                </div>
                <div class="info-form">
                  <form
                    class="form_submission"
                    method="POST"
                    onSubmit={onSubmitFunc}
                  >
                    <input
                      type="hidden"
                      name="url"
                      value="https://www.timesghostwriters.com/lp2/"
                    />
                    <input type="hidden" name="subject" value="Signup Form " />
                    <div class="row">
                      <div class="col-md-12">
                        <input
                          type="text"
                          class="required form-control"
                          required
                          name="name"
                          placeholder="Full Name"
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                        />
                        <input
                          type="number"
                          minlength="10"
                          maxlength="12"
                          class="required form-control  number ftpn"
                          required
                          name="phone"
                          placeholder="Phone No"
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                        />
                        <input
                          type="email"
                          class="required form-control email"
                          required
                          name="email"
                          placeholder="Email Address"
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                        <textarea
                          name="message"
                          class="required form-control"
                          required
                          placeholder="To help us understand better, enter a brief description about your project."
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <div class="col-md-12 ">
                        <div class="btnsub show-s">
                          {loading == true ? (
                            <div class="loader">
                              <img
                                height="auto"
                                width="auto"
                                title="img"
                                alt="loader"
                                src={loader}
                              />
                            </div>
                          ) : (
                            <button type="submit" class="btn btn-dark">
                              Activate Your Coupon Now
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="banner-btm">
          <div class="container">
            <h5>
              KEEP ALL THE RIGHTS &amp; REVENUES OF YOUR BOOK. GET PUBLISHED
              TODAY!
            </h5>
          </div>
        </div>
      </section>

      <section class="our-allow">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div class="row">
            <div class="col-md-3">
              <h2>
                We Publish <br />
                Your Book <br />
                On All Leading <br />
                Platforms!
              </h2>
            </div>
            <div class="col-md-9">
              <img
                height="auto"
                width="auto"
                src={images_our_allow}
                title="img"
                alt="img"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="our-publishing">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <h2 class="text-center zila">
            BOOK PUBLISHING <br />
            Hassle-Free and Affordable <br />
            Get Published with Us Now <br />
          </h2>
          <div class="row">
            <div class="col-md-7">
              <div class="txt">
                <ul>
                  <li>
                    <img
                      height="auto"
                      width="auto"
                      src={images_our_pub_ul}
                      title="img"
                      alt="img"
                    />{' '}
                    A premium cover to get your book noticed.
                  </li>
                  <li>
                    <img
                      height="auto"
                      width="auto"
                      src={images_our_pub_ul}
                      title="img"
                      alt="img"
                    />{' '}
                    EBook conversion to any format including HTML.
                  </li>
                  <li>
                    <img
                      height="auto"
                      width="auto"
                      src={images_our_pub_ul}
                      title="img"
                      alt="img"
                    />{' '}
                    Keep all revenues from your eBooks with our suggested price.
                  </li>
                  <li>
                    <img
                      height="auto"
                      width="auto"
                      src={images_our_pub_ul}
                      title="img"
                      alt="img"
                    />{' '}
                    The final step to get your eBook selling is publishing.
                  </li>
                  <li>
                    <img
                      height="auto"
                      width="auto"
                      src={images_our_pub_ul}
                      title="img"
                      alt="img"
                    />{' '}
                    Marketplace integration by having accounts on all platforms.
                  </li>
                  <li>
                    <img
                      height="auto"
                      width="auto"
                      src={images_our_pub_ul}
                      title="img"
                      alt="img"
                    />{' '}
                    Free setup for those new to the ghostwriting industry.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <p>
                The publishing industry has evolved considerably in the last
                decade to deal with digital disruption, piracy, changing reading
                habits, the rise of audiobooks, extreme competition, production
                problems, and excessive promotion. It is extremely hard to get a
                traditional publisher to even read your manuscript let alone
                offer you a publishing contract. Traditional publishers are
                generally bombarded with hundreds of manuscripts from tons of
                writers and agents. That is why choosing
                {WEBSITE_NAME} to get your book published is the way to go for
                new and established writers!
              </p>
              <div class="btn-block">
                <a href="javascript:;" class="btn btn-light chat">
                  Live Chat
                </a>
                <a
                  href="javascript:;"
                  data-fancybox=""
                  class="btn btn-dark various"
                  onClick={onOpenModal}
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="our-author">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div class="row">
            <div class="col-md-7">
              <h2 class="zila">
                Get your book published by us <br />
                And get all distribution perks <br />
                Without the worry of any <br />
                upfront cost.
              </h2>
              <p>
                Now you won’t have to worry about editing, formatting, or
                promoting your book. Our publishing services include all those
                for you!
              </p>
              <div class="seal">
                <img
                  height="auto"
                  width="auto"
                  src="images/images-form-seal.webp"
                  title="img"
                  alt="img"
                />
              </div>
              <div class="btn-block">
                <a href="javascript:;" class="btn btn-light chat">
                  Live Chat
                </a>
                <a
                  href="javascript:;"
                  data-fancybox=""
                  class="btn btn-dark various"
                  onClick={onOpenModal}
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="our-process">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <h2 class="text-center zila">
            {WEBSITE_NAME} Book <br />
            Publishing Process
          </h2>
          <h3>
            {WEBSITE_NAME} book publishing process is a simple six-step process
            that has been streamlined over the years to make the entire
            experience smooth, functional, and easy to understand for our
            clients. Check out our publishing process down below.
          </h3>
          <ProcessCarousel />
          {/* <div class="owl-process owl-carousel owl-theme">
            <div class="item">
              <div class="process">
                <h5>step 01</h5>
                <h4>
                  Connect <br /> with Us
                </h4>
                <p>
                  The first step of the process is for you to leave us a message
                  online with details of your project. We will then get back to
                  you and answer any questions you may have.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="process">
                <h5>step 02</h5>
                <h4>
                  Draft <br /> Review
                </h4>
                <p>
                  The second step is when you submit to us your book’s final and
                  complete draft and our experts will review it to see how they
                  can market it after publishing.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="process">
                <h5>step 03</h5>
                <h4>
                  Editing, Proofreading, <br /> &amp; Formatting
                </h4>
                <p>
                  The third step is when your book’s final draft is handed over
                  to our team of editors and formatters wherein they check for
                  any errors and format the book to industry standard.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="process">
                <h5>step 04</h5>
                <h4>Book Cover Design</h4>
                <p>
                  The fourth step is when our creative team of designers get to
                  work on designing your book’s front and back covers to make it
                  worthy of being picked up from a shelf.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="process">
                <h5>step 05</h5>
                <h4>
                  Book <br /> Publishing
                </h4>
                <p>
                  The fifth step is when we finally publish your book! We’ll
                  publish your book in print, digitally (on all platforms), and
                  even offer print-on-demand services.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="process">
                <h5>step 06</h5>
                <h4>Profit!</h4>
                <p>
                  The sixth step is quite simple: you sit back and enjoy the
                  revenue generated by your published book after we’re done
                  distributing and promoting it to maximize sales.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section class="our-amazon">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <h2 class="zila">
            Getting a Book Published Has Never Been This Easy
          </h2>
          <h3>Let us take away the hassle away and publish your book.</h3>
          <div class="btn-block">
            <a href="javascript:;" class="btn btn-light chat">
              Live Chat
            </a>
            <a
              href="javascript:;"
              data-fancybox=""
              class="btn btn-dark various"
              onClick={onOpenModal}
            >
              Get Started
            </a>
          </div>
          <a class="tel" href={PHONE_HREF}>
            <img
              height="auto"
              width="auto"
              src={images_amazon_tel}
              title="img"
              alt="img"
            />
            {PHONE}
          </a>
        </div>
      </section>
      <section class="our-testimonial">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <h2 class="zila">
            REVIEWS FROM OUR CLIENTS <br />
            See what our clients have to say about us!
          </h2>
          <TestimonialCarousel />
          {/* <div class="owl-testimonial owl-carousel owl-theme">
            <div class="item">
              <div class="info-testi">
                <p>
                  “I’ve had several of my books ghostwritten, edited, formatted,
                  designed, and published through
                  {WEBSITE_NAME}! They’ve taken my dreams of becoming a writer
                  by profession and made it a reality and now all I have to do
                  is come up with new book ideas.”
                </p>
                <h4>Arnold Moss</h4>
              </div>
            </div>
            <div class="item">
              <div class="info-testi">
                <p>
                  “As a writer who writes a lot of books every month, I never
                  have the time to edit my book let alone go after publishing
                  agencies to notice my manuscript.
                  {WEBSITE_NAME} has taken a load off my back with their editing
                  and publishing services!”
                </p>
                <h4>Lindsey Page</h4>
              </div>
            </div>
            <div class="item">
              <div class="info-testi">
                <p>
                  “It’s not easy keeping up with readers’ demands for sequels to
                  a book series you start. That’s why I outsource some of my
                  writing projects to
                  {WEBSITE_NAME}! Their team is excellent with both the writing
                  and the editing and they publish my work for me too!”
                </p>
                <h4>Gabriel Watts</h4>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section class="our-counter">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div class="row ">
            <div class="col-md-8 offset-4">
              <div class="row count-slider">
                <div class="col-lg-3">
                  <h6>1000</h6>
                  <p>
                    published books <br /> (in print)
                  </p>
                </div>
                <div class="col-lg-3">
                  <h6>#1</h6>
                  <p>
                    times NYT <br /> Bestsellers
                  </p>
                </div>
                <div class="col-lg-3">
                  <h6>15+</h6>
                  <p>bestsellers</p>
                </div>
                <div class="col-lg-3">
                  <h6>BCC</h6>
                  <p>
                    Featured Best <br /> Ghostwriting Agency
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div class="row">
            <div class="col-md-6">
              <h2>
                Contact us today to <br />
                receive a free <br />
                consultation
              </h2>
              <h3>Speak with our consultant now.</h3>
              <p>
                Feel free to reach out to us today to receive a free
                consultation from our experts. We will answer all your questions
                and help you get started on your journey to become a fully
                published author! We’re available 24/7.
              </p>
              <a href="javascript:;" class="btn speak-btn chat">
                Live Chat
              </a>
              <a class="tel" href={PHONE_HREF}>
                <img
                  height="auto"
                  width="auto"
                  src={images_amazon_tel}
                  title="img"
                  alt="img"
                />
                {PHONE}
              </a>
            </div>
            <div class="col-md-6">
              <div class="info-contact">
                <h4>Get in touch</h4>
                <p>
                  Use the form below to contact us regarding your ghostwriting
                  concerns. Please be as detailed as possible. You may also
                  email or call us for more information.
                </p>
                <div class="seal">
                  <img
                    height="auto"
                    width="auto"
                    src="images/images-form-seal.webp"
                    title="img"
                    alt="img"
                  />
                </div>
                <div class="info-form">
                  <form
                    class="form_submission"
                    method="POST"
                    onSubmit={onSubmitFunc}
                  >
                    <div class="row">
                      <div class="col-md-7">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          class="required form-control"
                          required
                          aria-required="true"
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                        />
                        <input
                          type="number"
                          minlength="10"
                          maxlength="12"
                          class="required form-control ftpn"
                          required
                          name="phone"
                          placeholder="Your Phone"
                          aria-required="true"
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                        />
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          class="required email form-control"
                          required
                          aria-required="true"
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                      </div>
                      <div class="col-md-5">
                        <textarea
                          name="message"
                          class="required form-control"
                          placeholder="To help us understand better, enter a brief description about your project."
                          aria-required="true"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    {loading == true ? (
                      <div class="loader">
                        <img
                          height="auto"
                          width="auto"
                          title="img"
                          alt="loader"
                          src={loader}
                        />
                      </div>
                    ) : (
                      <input
                        type="submit"
                        name="submit"
                        class="btn btn-red "
                        value="Submit Your Request"
                      />
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section class="copy">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p>
                {' '}
                Copyright ©{year} -{WEBSITE_NAME}. All Rights Reserved
              </p>
            </div>
            <div class="col-md-6">
              <ul class="term">
                <li>
                  <a href="../terms.php">Terms &amp; Condition</a>
                </li>
                <li>
                  <a href="../privacy.php">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
}

export default App;
