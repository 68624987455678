import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { loader } from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "amazonkdppublishers.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=237046';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonkdppublishers.org/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="">
      <Modal
        show={open}
        onHide={onCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div class="popup-form-sec">
            <div class="cross-mark" onClick={onCloseModal}>
              <a href="javascript:;">
                <i class="fa fa-times"></i>
              </a>
            </div>
            <div class="popup-form-sec-title">
              <h2>We are here to help!</h2>
              <p>
                Providing you the perfect solution for your business needs.
                Let's work together and unlock doors to success.
              </p>
            </div>
            <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
              <div class="row">
                <div class="col-md-12">
                  <input
                    type="text"
                    class="required form-control"
                    required
                    name="name"
                    placeholder="Your Name"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  <input
                    type="email"
                    class="required form-control"
                    required
                    name="email"
                    placeholder="Your Email"
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    minlength="10"
                    maxlength="12"
                    class="required form-control ftpn"
                    required
                    name="phone"
                    placeholder="Your Phone"
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                  <textarea
                    autocomplete="nope"
                    name="message"
                    class="required form-control"
                    required
                    placeholder="To help us understand better, enter a brief description about your project."
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <div class="col-md-12">
                  {loading == true ? (
                    <div class="loader">
                      <img
                        height="auto"
                        width="auto"
                        title="img"
                        alt="loader"
                        src={loader}
                      />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      class="btn btn-black"
                      name="send_data"
                      value="Submit Now"
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
