import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    ADDRESS,
    EMAIL,
    EMAIL_HREF,
    PHONE,
    PHONE_HREF,
    WEBSITE_LOGO,
    WEBSITE_NAME,
  } from "../config";

const TestimonialCarousel = () => {
    return (
        <OwlCarousel class="owl-testimonial owl-carousel owl-theme" loop margin={10} nav dots={false} items={3}>
            <div class="item">
              <div class="info-testi">
                <p>
                  “I’ve had several of my books ghostwritten, edited, formatted,
                  designed, and published through
                  {WEBSITE_NAME}! They’ve taken my dreams of becoming a writer
                  by profession and made it a reality and now all I have to do
                  is come up with new book ideas.”
                </p>
                <h4>Arnold Moss</h4>
              </div>
            </div>
            <div class="item">
              <div class="info-testi">
                <p>
                  “As a writer who writes a lot of books every month, I never
                  have the time to edit my book let alone go after publishing
                  agencies to notice my manuscript.
                  {WEBSITE_NAME} has taken a load off my back with their editing
                  and publishing services!”
                </p>
                <h4>Lindsey Page</h4>
              </div>
            </div>
            <div class="item">
              <div class="info-testi">
                <p>
                  “It’s not easy keeping up with readers’ demands for sequels to
                  a book series you start. That’s why I outsource some of my
                  writing projects to
                  {WEBSITE_NAME}! Their team is excellent with both the writing
                  and the editing and they publish my work for me too!”
                </p>
                <h4>Gabriel Watts</h4>
              </div>
            </div>
        </OwlCarousel>
    );
};
  
export default TestimonialCarousel;
