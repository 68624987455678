import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ProcessCarousel = () => {
    return (
        <OwlCarousel className='owl-process wl-process owl-carousel owl-theme' loop margin={40} nav dots={false} items={4}>
            <div class="item">
                <div class="process">
                <h5>step 01</h5>
                <h4>
                    Connect <br /> with Us
                </h4>
                <p>
                    The first step of the process is for you to leave us a message
                    online with details of your project. We will then get back to
                    you and answer any questions you may have.
                </p>
                </div>
            </div>
            <div class="item">
                <div class="process">
                <h5>step 02</h5>
                <h4>
                    Draft <br /> Review
                </h4>
                <p>
                    The second step is when you submit to us your book’s final and
                    complete draft and our experts will review it to see how they
                    can market it after publishing.
                </p>
                </div>
            </div>
            <div class="item">
                <div class="process">
                <h5>step 03</h5>
                <h4>
                    Editing, Proofreading, <br /> &amp; Formatting
                </h4>
                <p>
                    The third step is when your book’s final draft is handed over
                    to our team of editors and formatters wherein they check for
                    any errors and format the book to industry standard.
                </p>
                </div>
            </div>
            <div class="item">
                <div class="process">
                <h5>step 04</h5>
                <h4>Book Cover Design</h4>
                <p>
                    The fourth step is when our creative team of designers get to
                    work on designing your book’s front and back covers to make it
                    worthy of being picked up from a shelf.
                </p>
                </div>
            </div>
            <div class="item">
                <div class="process">
                <h5>step 05</h5>
                <h4>
                    Book <br /> Publishing
                </h4>
                <p>
                    The fifth step is when we finally publish your book! We’ll
                    publish your book in print, digitally (on all platforms), and
                    even offer print-on-demand services.
                </p>
                </div>
            </div>
            <div class="item">
                <div class="process">
                <h5>step 06</h5>
                <h4>Profit!</h4>
                <p>
                    The sixth step is quite simple: you sit back and enjoy the
                    revenue generated by your published book after we’re done
                    distributing and promoting it to maximize sales.
                </p>
                </div>
            </div>
        </OwlCarousel>
    );
};
  
export default ProcessCarousel;