const Admin_Panel = 'info@amazonkdppublishers.org';
const PHONE = '(855) 587-7623';
const PHONE_HREF = 'tel:8555877623';
// const PHONE_TWO = '+61 489 951 988';
// const PHONE_TWO_HREF = 'tel:61489951988';
const EMAIL = 'info@amazonkdppublishers.org';
const EMAIL_HREF = 'mailto:info@amazonkdppublishers.org';
const CONTACT_EMAIL = 'info@amazonkdppublishers.org';
const CONTACT_EMAIL_HREF = 'mailto:info@amazonkdppublishers.org';
const ADDRESS = '224 W 35th St Ste 500 PMB 33 New York, NY 10001';
const WEBSITE_NAME = 'Amazon KDP Publishers';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO = 'https://www.amazonkdppublishers.org/logo.png';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  //   PHONE_TWO,
  //   PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
